import { DEFAULT_PLATFORM_HEIGHT } from "constants/settings";

export const AGES = [
  {
    value: "A",
    code: "A",
    name: "16-18",
    ageFrom: 16,
    ageTo: 18,
    subItems: [
      {
        value: "A1(16)",
        code: "A1",
        name: "A1 16 years old",
        ageFrom: 16,
        ageTo: 16,
      },
      {
        value: "A2(17)",
        code: "A2",
        name: "A2 17 years old",
        ageFrom: 17,
        ageTo: 17,
      },
      {
        value: "A3(18)",
        code: "A3",
        name: "A3 18 years old",
        ageFrom: 18,
        ageTo: 18,
      },
    ],
  },
  {
    value: "B",
    code: "B",
    name: "14-15",
    ageFrom: 14,
    ageTo: 15,
    subItems: [
      {
        value: "B1(14)",
        code: "B1",
        name: "B1 14 years old",
        ageFrom: 14,
        ageTo: 14,
      },
      {
        value: "B2(15)",
        code: "B2",
        name: "B2 15 years old",
        ageFrom: 15,
        ageTo: 15,
      },
    ],
  },
  {
    value: "C",
    code: "C",
    name: "12-13",
    ageFrom: 12,
    ageTo: 13,
    subItems: [
      {
        value: "C1(12)",
        code: "C1",
        name: "C1 12 years old",
        ageFrom: 12,
        ageTo: 12,
      },
      {
        value: "C2(13)",
        code: "C2",
        name: "C2 13 years old",
        ageFrom: 13,
        ageTo: 13,
      },
    ],
  },
  {
    value: "D",
    code: "D",
    name: "10-11",
    ageFrom: 10,
    ageTo: 11,
    subItems: [
      {
        value: "D1(10)",
        code: "D1",
        name: "D1 10 years old",
        ageFrom: 10,
        ageTo: 10,
      },
      {
        value: "D2(11)",
        code: "D2",
        name: "D2 11 years old",
        ageFrom: 11,
        ageTo: 11,
      },
    ],
  },
  {
    value: "E",
    code: "E",
    name: "9 and under",
    ageFrom: 7,
    ageTo: 9,
    subItems: [
      {
        value: "E1(7)",
        code: "E1",
        name: "E1 7 years old",
        ageFrom: 7,
        ageTo: 7,
      },
      {
        value: "E2(8)",
        code: "E2",
        name: "E2 8 years old",
        ageFrom: 8,
        ageTo: 8,
      },
      {
        value: "E3(9)",
        code: "E3",
        name: "E3 9 years old",
        ageFrom: 9,
        ageTo: 9,
      },
    ],
  },
  { value: "Open", code: "Elite", name: "Open", ageFrom: 2, ageTo: 120 },
];

export const AGE_GROUP_X4 = [
  { value: "21-24", code: "Elite", name: "21-24" },
  { value: "25-29", code: "Elite", name: "25-29" },
  { value: "30-34", code: "Elite", name: "30-34" },
  { value: "35-39", code: "Elite", name: "35-39" },
  { value: "40-44", code: "Elite", name: "40-44" },
  { value: "45-49", code: "Elite", name: "45-49" },
  { value: "50-54", code: "Elite", name: "50-54" },
  { value: "55-59", code: "Elite", name: "55-59" },
  { value: "60-64", code: "Elite", name: "60-64" },
  { value: "65-69", code: "Elite", name: "65-69" },
  { value: "70-74", code: "Elite", name: "70-74" },
  { value: "75-79", code: "Elite", name: "75-79" },
  { value: "80-84", code: "Elite", name: "80-84" },
  { value: "85-89", code: "Elite", name: "85-89" },
  { value: "90+", code: "Elite", name: "90+" },
];

export const AGE_GROUP_X9 = [
  { value: "21-29", code: "Elite", name: "21-29" },
  { value: "30-39", code: "Elite", name: "30-39" },
  { value: "40-49", code: "Elite", name: "40-49" },
  { value: "50-59", code: "Elite", name: "50-59" },
  { value: "60-69", code: "Elite", name: "60-69" },
  { value: "70-79", code: "Elite", name: "70-79" },
  { value: "80-89", code: "Elite", name: "80-89" },
  { value: "90+", code: "Elite", name: "90+" },
];

export const AGE_GROUP_X14 = [
  { value: "21-34", code: "Elite", name: "21-34" },
  { value: "35-49", code: "Elite", name: "35-49" },
  { value: "50-64", code: "Elite", name: "50-64" },
  { value: "65-79", code: "Elite", name: "65-79" },
  { value: "80+", code: "Elite", name: "80+" },
];

export const FEES_LABELS = {
  diverEventFee: "Diver event fee",
  noviceEventFee: "Novice event fee",
  juniorEventFee: "Junior event fee",
  seniorEventFee: "Senior event fee",
  masterEventFee: "Master event fee",
  synchroEventFee: "Synchro event fee",
  lateFee: "Late fee for event",
  lateFeeAfter: "Late fee for event starts from",
  spectatorDailyPass: "Spectator Daily pass",
  spectatorAllMeetPass: "Spectator all-meet pass",
  diverMeetFee: "Diver meet fee",
  lateFeeMeetForMeet: "Late fee for meet",
  lateFeeMeetForMeetAfter: "Late fee for meet start from",
  freeOfCharge: "Free of charge",
};

export const HEIGHT_RANGES = {
  USAD: [
    { id: 1, value: "1M", meters: 1 },
    { id: 2, value: "3M", meters: 3 },
    {
      id: 3,
      value: "Platform",
      meters: DEFAULT_PLATFORM_HEIGHT,
      isPlatform: true,
    },
    {
      id: 4,
      value: "Mixed",
      meters: 0,
    },
  ],
  FINA: [
    { id: 1, value: "1M", meters: 1 },
    { id: 2, value: "3M", meters: 3 },
    {
      id: 3,
      value: "Platform",
      meters: DEFAULT_PLATFORM_HEIGHT,
      isPlatform: true,
    },
    {
      id: 4,
      value: "Mixed",
      meters: 0,
    },
  ],
  NFHS: [
    { id: 1, value: "1M", meters: 1 },
    { id: 2, value: "3M", meters: 3 },
    {
      id: 3,
      value: "Mixed",
      meters: 0,
    },
  ],
  NCAA: [
    { id: 1, value: "1M", meters: 1 },
    { id: 2, value: "3M", meters: 3 },
    {
      id: 3,
      value: "Platform",
      meters: DEFAULT_PLATFORM_HEIGHT,
      isPlatform: true,
    },
    {
      id: 4,
      value: "Mixed",
      meters: 0,
    },
  ],
  AAU: [
    { id: 1, value: "1M", meters: 1 },
    { id: 2, value: "3M", meters: 3 },
    {
      id: 3,
      value: "Platform",
      meters: DEFAULT_PLATFORM_HEIGHT,
      isPlatform: true,
    },
    {
      id: 4,
      value: "Mixed",
      meters: 0,
    },
  ],
  DPC: [
    { id: 1, value: "1M", meters: 1 },
    { id: 2, value: "3M", meters: 3 },
    {
      id: 3,
      value: "Platform",
      meters: DEFAULT_PLATFORM_HEIGHT,
      isPlatform: true,
    },
    {
      id: 4,
      value: "Mixed",
      meters: 0,
    },
  ],
  YMCA: [
    { id: 1, value: "1M", meters: 1 },
    { id: 2, value: "3M", meters: 3 },
    {
      id: 3,
      value: "Platform",
      meters: DEFAULT_PLATFORM_HEIGHT,
      isPlatform: true,
    },
    {
      id: 4,
      value: "Mixed",
      meters: 0,
    },
  ],
  NAIA: [
    { id: 1, value: "1M", meters: 1 },
    { id: 2, value: "3M", meters: 3 },
    {
      id: 3,
      value: "Platform",
      meters: DEFAULT_PLATFORM_HEIGHT,
      isPlatform: true,
    },
    {
      id: 4,
      value: "Mixed",
      meters: 0,
    },
  ],
  NJCAA: [
    { id: 1, value: "1M", meters: 1 },
    { id: 2, value: "3M", meters: 3 },
    {
      id: 3,
      value: "Platform",
      meters: DEFAULT_PLATFORM_HEIGHT,
      isPlatform: true,
    },
    {
      id: 4,
      value: "Mixed",
      meters: 0,
    },
  ],
  // more values that should be in config / dive_governing body
  MCDL: [
    { id: 1, value: "1M", meters: 1 },
    { id: 2, value: "3M", meters: 3 },
    {
      id: 3,
      value: "Platform",
      meters: DEFAULT_PLATFORM_HEIGHT,
      isPlatform: true,
    },
    {
      id: 4,
      value: "Mixed",
      meters: 0,
    },
  ],
  CMDL: [
    { id: 1, value: "1M", meters: 1 },
    { id: 2, value: "3M", meters: 3 },
    {
      id: 3,
      value: "Platform",
      meters: DEFAULT_PLATFORM_HEIGHT,
      isPlatform: true,
    },
    {
      id: 4,
      value: "Mixed",
      meters: 0,
    },
  ],
  RMDL: [
    { id: 1, value: "1M", meters: 1 },
    { id: 2, value: "3M", meters: 3 },
    {
      id: 3,
      value: "Platform",
      meters: DEFAULT_PLATFORM_HEIGHT,
      isPlatform: true,
    },
    {
      id: 4,
      value: "Mixed",
      meters: 0,
    },
  ],
  ESF: [
    { id: 1, value: "1M", meters: 1 },
    { id: 2, value: "3M", meters: 3 },
    {
      id: 3,
      value: "Platform",
      meters: DEFAULT_PLATFORM_HEIGHT,
      isPlatform: true,
    },
    {
      id: 4,
      value: "Mixed",
      meters: 0,
    },
  ],
  GMAC: [
    { id: 1, value: "1M", meters: 1 },
    { id: 2, value: "3M", meters: 3 },
    {
      id: 3,
      value: "Platform",
      meters: DEFAULT_PLATFORM_HEIGHT,
      isPlatform: true,
    },
    {
      id: 4,
      value: "Mixed",
      meters: 0,
    },
  ],
  JCDC: [
    { id: 1, value: "1M", meters: 1 },
    { id: 2, value: "3M", meters: 3 },
    // {
    //   id: 3,
    //   value: "Platform",
    //   meters: DEFAULT_PLATFORM_HEIGHT,
    //   isPlatform: true,
    // },
    {
      id: 4,
      value: "Mixed",
      meters: 0,
    },
  ],
};

export const PAYMENT_KEYS = {
  CHECK_BY_EMAIL: "checkingByEmailPayment",
  VENMO: "venmoPayment",
  DIRECT_DEPOSIT: "directDeposit",
};

export const PAYMENT_METHOD_NAMES = {
  CHECK_BY_EMAIL: "CHECK_BY_EMAIL",
  VENMO: "VENMO",
  DIRECT_DEPOSIT: "DIRECT_DEPOSIT",
};

export const getPaymentMethodsConfig = (t) => [
  {
    radioName: PAYMENT_METHOD_NAMES.CHECK_BY_EMAIL,
    label: `${t("Check by mail")}`,
    inputs: [
      { name: "payableCheck", label: `${t("Make check payable to")}` },
      { name: "mallingAddress", label: `${t("Mailing address")}` },
      { name: "streetAddress", label: `${t("Street address 1")}` },
      { name: "additionalStreetAddress", label: `${t("Street address 2")}` },
      { name: "city", label: `${t("City")}` },
      { name: "state", label: `${t("State")}` },
      { name: "zip", label: `${t("Zip")}` },
    ],
  },
  {
    radioName: PAYMENT_METHOD_NAMES.VENMO,
    label: `${t("Venmo")}`,
    inputs: [
      { name: "venmoUserId", label: `${t("Enter your Venmo user id")}` },
    ],
  },
  {
    radioName: PAYMENT_METHOD_NAMES.DIRECT_DEPOSIT,
    label: `${t("Direct deposit")}`,
    inputs: [
      { name: "bankName", label: `${t("Bank name")}` },
      { name: "bankStreetAddress", label: `${t("Bank street address")}` },
      { name: "bankRoutingNumber", label: `${t("Bank routing number")}` },
      { name: "bankAccountNumber", label: `${t("Bank account number")}` },
    ],
  },
];

export const FEES_NAMES = {
  DIVER_EVENT: "diverEventFee",
  NOVICE_EVENT: "noviceEventFee",
  JUNIOR_EVENT: "juniorEventFee",
  SENIOR_EVENT: "seniorEventFee",
  MASTER_EVENT: "masterEventFee",
  SYNCHRO_EVENT: "synchroEventFee",
  LATE_EVENT: "lateFee",
  LATE_FEE_AFTER: "lateFeeAfter",
  SPECTATOR_DAILY: "spectatorDailyPass",
  SPECTATOR_ALL_MEET: "spectatorAllMeetPass",
  DIVER_MEET: "diverMeetFee",
  CUSTOM_FEE: "customFee",
  CUSTOM_MEET: "customFee",
  CUSTOM_NAME: "customFeeName",
  CUSTOM_DESCRIPTION: "customFeeDescription",
  LATE_MEET: "lateFeeMeetForMeet",
  LATE_FOR_MEET_AFTER: "lateFeeMeetForMeetAfter",
  FREE_OF_CHARGE: "freeOfCharge",
};

export const FEES_NAMES_FOR_API = {
  diverEventFee: "DIVER_EVENT",
  noviceEventFee: "NOVICE_EVENT",
  juniorEventFee: "JUNIOR_EVENT",
  seniorEventFee: "SENIOR_EVENT",
  masterEventFee: "MASTER_EVENT",
  synchroEventFee: "SYNCHRO_EVENT",
  lateFee: "LATE_EVENT",
  spectatorDailyPass: "SPECTATOR_DAILY",
  spectatorAllMeetPass: "SPECTATOR_ALL_MEET",
  diverMeetFee: "DIVER_MEET",
  customFee: "CUSTOM_MEET",
  lateFeeMeetForMeet: "LATE_MEET",
  freeOfCharge: "FREE_OF_CHARGE",
};

export const MIN_SEARCH_KEY_LENGTH = 3;
export const PAYMENT_HELPER_TEXT = "How would you like to receive your payment";
